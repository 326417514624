import * as React from 'react'

const SearchInput = ({value, placeholder, onChange}) => {

  const onValueChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <form>
      <input
        type={'text'}
        className={'form-control'}
        id={'reviewSearchField'}
        value={value}
        placeholder={placeholder}
        onChange={onValueChange}
      />
    </form>
  )
}

export default SearchInput;