import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import ReviewCard from "../../components/reviewCard";
import {useState} from "react";
import SearchInput from "../../components/SearchInput";

const ReviewPage = ({ data }) => {
  const [searchString, setSearchString] = useState('')

  let results = data.allMdx.nodes.slice(1).filter((node) => {
    const fm = node.frontmatter
    console.log(fm)
    if (fm.title.toLowerCase().includes(searchString.toLowerCase())) {
      return true;
    }
    if (fm.summary.toLowerCase().includes(searchString.toLowerCase())) {

    }
  });

  return (
    <Layout
      pageTitle="Feature and TV Reviews"
      pageSummary={"Full length reviews of my favourite shows and feature films"}
    >
      <div className="row justify-content-center mb-3">
        {
          data.allMdx.nodes.slice(0,1).map((node) => {
            return (
              <div className="col">
                <ReviewCard key={node.id} node={node} />
              </div>
            )
          })
        }
      </div>
      <div className={'row'}>
        <h2 className="text-center mb-3">All reviews</h2>
      </div>
      <div className="row justify-content-center">
        <div className={'col-12 mb-3'}>
          <SearchInput
            value={searchString}
            placeholder={'Search'}
            onChange={setSearchString}
            />
        </div>
        {
          results.map((node) => {
            return (
              <div className="col-md-6">
                <ReviewCard key={node.id} node={node} />
              </div>
            )
          })
        }
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          summary
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
        slug
      }
    }
  }
`;

export default ReviewPage;
